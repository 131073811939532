import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { HousekeeperSettingsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class HousekeeperSettingsStoreEffects {
  constructor(
    private dataService: HousekeeperSettingsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyId }) =>
        this.dataService.load(propertyId).pipe(
          map(({ data }: IResponseSuccess<any>) => {
            return fromActions.loadSuccess({ settings: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, onSuccess, onFailure }) =>
        this.dataService.create(request).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');

            if (onSuccess) {
              onSuccess();
            }

            return fromActions.createSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            if (onFailure) {
              onFailure();
            }

            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ propertyId, settingId }) =>
        this.dataService.delete(propertyId, settingId).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.deleteSuccess({ settingId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
