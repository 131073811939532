import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { HousekeeperSortable } from '../../models';
import { HousekeeperSortablesService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class HousekeeperSortablesStoreEffects {
  constructor(
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private dataService: HousekeeperSortablesService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyId }) =>
        this.dataService.load(propertyId).pipe(
          map(({ data }: IResponseSuccess<HousekeeperSortable[]>) => {
            return fromActions.loadSuccess({ sortables: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  sort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sortRequest),
      switchMap(({ propertiesIds, sortables }) =>
        this.dataService.sort(propertiesIds, sortables).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.sortSuccess({ sortables });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.sortFailure(error));
          }),
        ),
      ),
    ),
  );
}
