import { createAction, props } from '@ngrx/store';

import {
  CreateHousekeeperTaskRequest,
  HousekeeperTask,
  HousekeeperTasks,
  HousekeeperTasksCleanable,
} from '../../models';

export const loadRequest = createAction(
  '[Housekeeper Tasks] Load Request',
  props<{ propertyId: number }>(),
);
export const loadSuccess = createAction(
  '[Housekeeper Tasks] Load Success',
  props<{ tasks: HousekeeperTasks }>(),
);
export const loadFailure = createAction(
  '[Housekeeper Tasks] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Housekeeper Tasks] Create Request',
  props<{
    propertyId: number;
    request: CreateHousekeeperTaskRequest;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const createSuccess = createAction('[Housekeeper Tasks] Create Success');
export const createFailure = createAction(
  '[Housekeeper Tasks] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Housekeeper Tasks] Update Request',
  props<{
    propertyId: number;
    task: HousekeeperTask;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const updateSuccess = createAction(
  '[Housekeeper Tasks] Update Success',
  props<{ task: HousekeeperTask }>(),
);
export const updateFailure = createAction(
  '[Housekeeper Tasks] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Housekeeper Tasks] Delete Request',
  props<{
    propertyId: number;
    task: HousekeeperTask;
  }>(),
);
export const deleteSuccess = createAction(
  '[Housekeeper Tasks] Delete Success',
  props<{
    task: HousekeeperTask;
  }>(),
);
export const deleteFailure = createAction(
  '[Housekeeper Tasks] Delete Failure',
  props<{ error: any }>(),
);

export const detachRequest = createAction(
  '[Housekeeper Tasks] Detach Request',
  props<{
    propertyId: number;
    cleanable: HousekeeperTasksCleanable;
    task: HousekeeperTask;
  }>(),
);
export const detachSuccess = createAction(
  '[Housekeeper Tasks] Detach Success',
  props<{
    cleanable: HousekeeperTasksCleanable;
    task: HousekeeperTask;
  }>(),
);
export const detachFailure = createAction(
  '[Housekeeper Tasks] Detach Failure',
  props<{ error: any }>(),
);

export const setActiveRequest = createAction(
  '[Housekeeper Tasks] Set Active Request',
  props<{
    propertyId: number;
    cleanable: HousekeeperTasksCleanable;
    task: HousekeeperTask;
    active: boolean;
  }>(),
);
export const setActiveSuccess = createAction(
  '[Housekeeper Tasks] Set Active Success',
  props<{
    cleanable: HousekeeperTasksCleanable;
    task: HousekeeperTask;
    active: boolean;
  }>(),
);
export const setActiveFailure = createAction(
  '[Housekeeper Tasks] Set Active Failure',
  props<{ error: any }>(),
);

export const orderRequest = createAction(
  '[Housekeeper Tasks] Order Request',
  props<{
    propertyId: number;
    cleanable: HousekeeperTasksCleanable;
  }>(),
);
export const orderSuccess = createAction(
  '[Housekeeper Tasks] Order Success',
  props<{
    propertyId: number;
    cleanable: HousekeeperTasksCleanable;
  }>(),
);
export const orderFailure = createAction(
  '[Housekeeper Tasks] Order Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Housekeeper Tasks] Reset State');
