import { HousekeeperTasks } from '../../models';

export interface State {
  tasks: HousekeeperTasks;
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  tasks: null,
  isLoading: false,
  error: null,
};
