import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HousekeeperSortablesStoreEffects } from './effects';
import { housekeeperSortablesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('housekeeperSortables', housekeeperSortablesReducer),
    EffectsModule.forFeature([HousekeeperSortablesStoreEffects]),
  ],
})
export class HousekeeperSortablesStoreModule {}
