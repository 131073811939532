import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import { featureAdapter, initialState, State } from './state';

export const reducer = createReducer(
  initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { sortables }) =>
    featureAdapter.setAll(sortables, { ...state, isLoading: false }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.sortRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.sortSuccess, (state, { sortables }) =>
    featureAdapter.setAll(sortables, { ...state, isLoading: false }),
  ),
  on(fromActions.sortFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => initialState),
);

export function housekeeperSortablesReducer(
  state: State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
