import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { CleaneablesMapping } from '../../models';
import { CleanablesService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class CleanablesStoreEffects {
  constructor(
    private dataService: CleanablesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadSlugs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSlugsRequest),
      switchMap(() =>
        this.dataService.loadSlugs().pipe(
          map(({ data }: IResponseSuccess<string[]>) => {
            return fromActions.loadSlugsSuccess({ slugs: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadSlugsFailure(error));
          }),
        ),
      ),
    ),
  );

  loadMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMappingRequest),
      switchMap(({ propertyId }) =>
        this.dataService.loadMapping(propertyId).pipe(
          map(({ data }: IResponseSuccess<CleaneablesMapping>) => {
            return fromActions.loadMappingSuccess({
              mapping: data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadMappingFailure(error));
          }),
        ),
      ),
    ),
  );
}
