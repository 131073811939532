import { Action, createReducer, on } from '@ngrx/store';
import { mapValues } from 'lodash';

import {
  HousekeeperTask,
  HousekeeperTasks,
  HousekeeperTasksCleanable,
} from '../../models';

import * as fromActions from './actions';
import { initialState, State } from './state';

export const reducer = createReducer(
  initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { tasks }) => ({
    ...state,
    tasks,
    isLoading: false,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({ ...state, isLoading: true })),
  on(fromActions.deleteSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(fromActions.setActiveSuccess, (state, { task, cleanable, active }) => ({
    ...state,
    tasks: mapTasks(state, (tasks, _cleanable) => {
      return tasks.map((_task) => {
        if (
          _task.id !== task.id ||
          _cleanable.cleanable_id !== cleanable.cleanable_id
        ) {
          return _task;
        }

        return { ..._task, active };
      });
    }),
  })),

  on(fromActions.updateSuccess, (state, { task }) => ({
    ...state,
    tasks: mapTasks(state, (tasks) => {
      return tasks.map((_task) => {
        if (_task.id !== task.id) {
          return _task;
        }

        return { ..._task, ...task };
      });
    }),
  })),

  on(fromActions.deleteSuccess, (state, { task }) => ({
    ...state,
    tasks: mapTasks(state, (tasks) => {
      return tasks.filter((_task) => {
        return _task.id !== task.id;
      });
    }),
  })),

  on(fromActions.detachSuccess, (state, { task, cleanable }) => ({
    ...state,
    tasks: mapTasks(state, (tasks, _cleanable) => {
      return tasks.filter((_task) => {
        return (
          _task.id !== task.id ||
          _cleanable.cleanable_id !== cleanable.cleanable_id
        );
      });
    }),
  })),

  on(fromActions.orderSuccess, (state, { cleanable }) => ({
    ...state,
    tasks: mapTasks(state, (tasks, _cleanable) => {
      if (_cleanable.cleanable_id !== cleanable.cleanable_id) {
        return tasks;
      }

      return cleanable.tasks;
    }),
  })),

  on(fromActions.resetState, () => initialState),
);

function mapTasks(
  state: State,
  mapper: (
    tasks: HousekeeperTask[],
    cleanable: HousekeeperTasksCleanable,
  ) => HousekeeperTask[],
): HousekeeperTasks {
  return mapValues(state.tasks, (cleanables) =>
    cleanables.map((cleanable) => ({
      ...cleanable,
      tasks: mapper(cleanable.tasks, cleanable),
    })),
  );
}

export function housekeeperTasksReducer(
  state: State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
