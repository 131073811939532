import { createAction, props } from '@ngrx/store';

import {
  CreateHousekeeperSettingRequest,
  HousekeeperSetting,
} from '../../models';

export const loadRequest = createAction(
  '[Housekeeper Settings] Load Request',
  props<{ propertyId: number }>(),
);
export const loadSuccess = createAction(
  '[Housekeeper Settings] Load Success',
  props<{ settings: HousekeeperSetting[] }>(),
);
export const loadFailure = createAction(
  '[Housekeeper Settings] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Housekeeper Settings] Create Request',
  props<{
    request: CreateHousekeeperSettingRequest;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const createSuccess = createAction(
  '[Housekeeper Settings] Create Success',
);
export const createFailure = createAction(
  '[Housekeeper Settings] Create Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Housekeeper Settings] Delete Request',
  props<{
    propertyId: number;
    settingId: number;
  }>(),
);
export const deleteSuccess = createAction(
  '[Housekeeper Settings] Delete Success',
  props<{ settingId: number }>(),
);
export const deleteFailure = createAction(
  '[Housekeeper Settings] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Housekeeper Settings] Reset State');
