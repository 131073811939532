import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HousekeeperSettingsStoreEffects } from './effects';
import { housekeeperSettingsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('housekeeperSettings', housekeeperSettingsReducer),
    EffectsModule.forFeature([HousekeeperSettingsStoreEffects]),
  ],
})
export class HousekeeperSettingsStoreModule {}
