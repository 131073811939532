import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy, isEmpty } from 'lodash';

import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>('housekeeperSettings');

export const selectSettings =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectSettingsGroupedByCleanableType = createSelector(
  selectSettings,
  (settings) => {
    const groupedSettings = groupBy(settings, 'cleanable.type');

    if (isEmpty(groupedSettings)) {
      return null;
    }

    return groupedSettings;
  },
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectError = createSelector(selectState, (state) => state.error);
