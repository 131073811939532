import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>('cleanables');

export const selectSlugs = createSelector(selectState, (state) => state.slugs);

export const selectMapping = createSelector(
  selectState,
  (state) => state.mapping,
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectError = createSelector(selectState, (state) => state.error);
