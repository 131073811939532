import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { HousekeeperTasks } from '../../models';
import { HousekeeperTasksService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class HousekeeperTasksStoreEffects {
  constructor(
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private dataService: HousekeeperTasksService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyId }) =>
        this.dataService.load(propertyId).pipe(
          map(({ data }: IResponseSuccess<HousekeeperTasks>) => {
            return fromActions.loadSuccess({ tasks: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ propertyId, request, onSuccess, onFailure }) =>
        this.dataService.create(propertyId, request).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');

            if (onSuccess) {
              onSuccess();
            }

            return fromActions.createSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            if (onFailure) {
              onFailure();
            }

            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ propertyId, task, onSuccess, onFailure }) =>
        this.dataService.update(propertyId, task).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');

            if (onSuccess) {
              onSuccess();
            }

            return fromActions.updateSuccess({ task });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            if (onFailure) {
              onFailure();
            }

            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ propertyId, task }) =>
        this.dataService.delete(propertyId, task.id).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.deleteSuccess({ task });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  detach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.detachRequest),
      switchMap(({ propertyId, cleanable, task }) =>
        this.dataService
          .detach(propertyId, cleanable.cleanable_id, task.id)
          .pipe(
            map(() => {
              this.notifications.success('notifications.generic_success');
              return fromActions.detachSuccess({ cleanable, task });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.detachFailure(error));
            }),
          ),
      ),
    ),
  );

  setActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setActiveRequest),
      switchMap(({ active, cleanable, task, propertyId }) =>
        this.dataService
          .setActive(propertyId, cleanable.cleanable_id, task.id, active)
          .pipe(
            map(() => {
              this.notifications.success('notifications.generic_success');
              return fromActions.setActiveSuccess({ cleanable, task, active });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.setActiveFailure(error));
            }),
          ),
      ),
    ),
  );

  order$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.orderRequest),
      switchMap(({ propertyId, cleanable }) =>
        this.dataService
          .order(
            propertyId,
            cleanable.cleanable_id,
            cleanable.tasks.map(({ id }) => id),
          )
          .pipe(
            map(() => {
              this.notifications.success('notifications.generic_success');
              return fromActions.orderSuccess({ propertyId, cleanable });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.orderFailure(error));
            }),
          ),
      ),
    ),
  );
}
