import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>('housekeeperTasks');

export const selectTasks = createSelector(selectState, (state) => state.tasks);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectError = createSelector(selectState, (state) => state.error);
