import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import { featureAdapter, initialState, State } from './state';

export const reducer = createReducer(
  initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { settings }) =>
    featureAdapter.setAll(settings, { ...state, isLoading: false }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({ ...state, isLoading: true })),
  on(fromActions.deleteSuccess, (state, { settingId }) =>
    featureAdapter.removeOne(settingId, { ...state, isLoading: false }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(fromActions.resetState, () => initialState),
);

export function housekeeperSettingsReducer(
  state: State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
