import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadSlugsSuccess, (state, { slugs }) => ({
    ...state,
    slugs,
  })),

  on(fromActions.loadMappingRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(fromActions.loadMappingSuccess, (state, { mapping }) => ({
    ...state,
    mapping,
    isLoading: false,
  })),
  on(fromActions.loadMappingFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function cleanablesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
