import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { HousekeeperSortable } from '../../models';

export const featureAdapter = createEntityAdapter<HousekeeperSortable>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<HousekeeperSortable> {
  isLoading: boolean;
  error: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
