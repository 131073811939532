import { createAction, props } from '@ngrx/store';

import { CleaneablesMapping } from '../../models';

export const loadSlugsRequest = createAction('[Cleanables] Load Slugs Request');
export const loadSlugsSuccess = createAction(
  '[Cleanables] Load Slugs Success',
  props<{ slugs: string[] }>(),
);
export const loadSlugsFailure = createAction(
  '[Cleanables] Load Slugs Failure',
  props<{ error: any }>(),
);

export const loadMappingRequest = createAction(
  '[Cleanables] Load Mapping Request',
  props<{ propertyId: number }>(),
);
export const loadMappingSuccess = createAction(
  '[Cleanables] Load Mapping Success',
  props<{ mapping: CleaneablesMapping }>(),
);
export const loadMappingFailure = createAction(
  '[Cleanables] Load Mapping Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Cleanables] Reset State');
