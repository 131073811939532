import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>('housekeeperSortables');

export const selectSortables =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectError = createSelector(selectState, (state) => state.error);
