import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { createAction, props } from '@ngrx/store';

import { HousekeeperSortable } from '../../models';

export const loadRequest = createAction(
  '[Housekeeper Sortables] Load Request',
  props<{ propertyId: number }>(),
);
export const loadSuccess = createAction(
  '[Housekeeper Sortables] Load Success',
  props<{ sortables: HousekeeperSortable[] }>(),
);
export const loadFailure = createAction(
  '[Housekeeper Sortables] Load Failure',
  props<{ error: any }>(),
);

export const sortRequest = createAction(
  '[Housekeeper Sortables] Sort Request',
  props<{ propertiesIds: number[]; sortables: HousekeeperSortable[] }>(),
);
export const sortSuccess = createAction(
  '[Housekeeper Sortables] Sort Success',
  props<{ sortables: HousekeeperSortable[] }>(),
);
export const sortFailure = createAction(
  '[Housekeeper Sortables] Sort Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Housekeeper Sortables] Reset State');
