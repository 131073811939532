import { CleaneablesMapping } from '../../models';

export interface State {
  slugs: string[];
  mapping: CleaneablesMapping;
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  slugs: null,
  mapping: null,
  isLoading: false,
  error: null,
};
